import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';
import { Text, Anchor } from '../../../Text';
import { Login } from '../Login';
import { Icon } from '../../../Icon';
import styles from './header.module.sass';
import HeaderCruises from './HeaderCruises/HeaderCruises';
import HeaderShips from './HeaderShips/HeaderShips';
import { useAuthStore } from '../../../../providers/RootStoreProvider';
import { useSearchStore } from '../../../../providers/SearchStoreProvider';
import { Logo } from '../../../Logo';
import { YaAndGaHeader } from '../../../../lib/YaAndGaHelper';
import { IconSVG } from '../../../SpriteSVG';
import { SkeletonCover } from '../../../Skeleton/SkeletonCover';
import { isClient } from '../../../../utils/isClient';

export const Header = observer(
  ({
    isMobile,
    shipsSea,
    isOutsPage,
    isStep,
    sea,
    topContent,
    showTopContent,
  }) => {
    const router = useRouter();
    const store = useAuthStore();
    const { mainResult } = useSearchStore();
    const firstLoading = mainResult.isFirstLoading;
    const { type } = router.query;
    const isSea =
      sea ||
      type === 'sea' ||
      router.asPath.startsWith('/sea_cruise') ||
      router.asPath.startsWith('/sea');
    const currentPage = router.pathname;
    const activePage = router.pathname;

    useEffect(() => {
      mainResult.setLoading();
    }, []);

    return (
      <header className={styles.wrapper}>
        {!isStep && (
          <>
            {isClient && showTopContent && topContent}
            <div className={styles.contactsBar}>
              <div className="container container--end container--between">
                <div className={styles.timeTable}>
                  {!isSea && !shipsSea && (
                    <>
                      <div className={styles.InTimeTable}>
                        <Icon
                          rightGap={8}
                          icon={
                            <IconSVG
                              name={'common/timetable'}
                              width={20}
                              height={20}
                            />
                          }
                          title="Расписание отправлений"
                        />

                        <a
                          className={styles.timeTableNone}
                          href="/departures"
                          target="_blank"
                          rel="nofollow noreferrer"
                          onClick={() => {
                            YaAndGaHeader('click_departure_schedule');
                          }}
                        >
                          <Text variant="timeTable">
                            Расписание отправлений
                          </Text>
                        </a>
                        <Icon
                          leftGap={8}
                          icon={
                            <IconSVG
                              name={'common/arrow'}
                              width={11}
                              height={10}
                            />
                          }
                          height={11}
                          width={11}
                          title="Стрелочка"
                        />
                      </div>
                      {/* <button
                    className={styles.agentEntry}
                    onClick={() => {
                      store.toggleB2bMode();
                    }}
                  >
                    <div className={styles.InTimeTable}>
                      <Icon
                        rightGap={8}
                        icon={
                          <IconSVG
                            name={'common/headphones'}
                            width={24}
                            height={24}
                          />
                        }
                        title="Расписание отправлений"
                      />

                      <div className={styles.timeTableNone}>
                        <Text variant="timeTable">
                          {store.isB2bMode
                            ? 'Выйти из сайта для агентов'
                            : 'Агентствам'}
                        </Text>
                      </div>
                      <Icon
                        leftGap={8}
                        icon={
                          <IconSVG
                            name={'common/arrow'}
                            width={11}
                            height={10}
                          />
                        }
                        height={11}
                        width={11}
                        title="Стрелочка"
                      />
                    </div>
                  </button> */}
                    </>
                  )}
                </div>
                {!store.isB2bMode && !isStep && (
                  <div className={styles.iconsWrapper}>
                    <div className="header-phone">
                      <a
                        href="tel:84958775834"
                        onClick={() => {
                          YaAndGaHeader('click_phone_number');
                        }}
                      >
                        +7 495 877-58-34
                      </a>
                    </div>

                    <a
                      target="_blank"
                      href="https://wa.clck.bar/79258328975"
                      rel="nofollow noreferrer"
                      onClick={() => {
                        YaAndGaHeader('click_whatsup');
                      }}
                    >
                      <Icon
                        leftGap={18}
                        icon={
                          <IconSVG name={'social/wa'} width={24} height={24} />
                        }
                        title="whatsapp"
                      />
                    </a>
                    <a
                      target="_blank"
                      href="https://t.me/+UYjTvBXN7slkOTZi"
                      rel="nofollow noreferrer"
                    >
                      <Icon
                        leftGap={10}
                        icon={
                          <IconSVG name={'social/tg'} width={24} height={24} />
                        }
                        title="telegram"
                      />
                    </a>
                    {/* <a
                      href="https://www.instagram.com/kruiz.online/"
                      target="_blank"
                      rel="nofollow noreferrer"
                      onClick={() => {
                        YaAndGaHeader('click_ig');
                      }}
                    >
                      <Icon
                        leftGap={10}
                        icon={
                          <IconSVG
                            name={'social/inst'}
                            width={24}
                            height={24}
                          />
                        }
                        title="instagram"
                      />
                    </a> */}
                    <a
                      href="https://vk.com/kruiz.online"
                      target="_blank"
                      rel="nofollow noreferrer"
                      onClick={() => {
                        YaAndGaHeader('click_vk');
                      }}
                    >
                      <Icon
                        leftGap={10}
                        icon={
                          <IconSVG name={'social/vk'} width={24} height={24} />
                        }
                        title="Вконтакте"
                      />
                    </a>
                    <a
                      href="https://ok.ru/group/52494082834611"
                      target="_blank"
                      rel="nofollow noreferrer"
                    >
                      <Icon
                        leftGap={10}
                        icon={
                          <IconSVG name={'social/ok'} width={24} height={24} />
                        }
                        title="ok"
                      />
                    </a>
                    <a
                      href="https://zen.yandex.ru/id/5cf7c5fd92015300af0beb57"
                      target="_blank"
                      rel="nofollow noreferrer"
                    >
                      <Icon
                        leftGap={10}
                        rightGap={18}
                        icon={
                          <IconSVG
                            name={'social/dzen'}
                            width={24}
                            height={24}
                          />
                        }
                        title="dzen.yandex"
                      />
                    </a>
                    <Anchor
                      weight="800"
                      href="https://kruiz.online/blog"
                      target="_blank"
                      rel="nofollow"
                      variant="linkAsbutton3"
                      onClick={() => {
                        YaAndGaHeader('click_blog');
                      }}
                    >
                      Блог
                    </Anchor>
                  </div>
                )}
              </div>
            </div>
          </>
        )}

        <div className={styles.headerNav} id="np">
          <nav className="container">
            {!store.isB2bMode && <Logo />}
            {!isStep && (
              <ul
                itemScope
                itemType={'https://schema.org/SiteNavigationElement'}
                className={styles.menu}
              >
                <li className={styles.menuItem}>
                  <Text
                    weight="700"
                    decoration="none"
                    onClick={() => {
                      YaAndGaHeader('hover_on_part_cruises');
                    }}
                    variant={
                      (currentPage.includes('cruise') && 'activePage') ||
                      (currentPage.includes('/result') && 'activePage') ||
                      (currentPage.includes('/twentyThirdYear') &&
                        'activePage') ||
                      (currentPage.includes('/popdir') && 'activePage')
                    }
                  >
                    Круизы
                  </Text>
                  {/* TODO: вынести в отдельный компонет, положить рядом с хедером */}
                  <HeaderCruises isSea={isSea} shipsSea={shipsSea} />
                </li>
                <li className={styles.menuItem}>
                  <Text
                    weight="700"
                    decoration="none"
                    onClick={() => {
                      YaAndGaHeader('hover_on_part_ships');
                    }}
                    variant={
                      (currentPage.includes('liners') && 'activePage') ||
                      (currentPage.includes('ships') && 'activePage')
                    }
                  >
                    {isSea || shipsSea ? 'Лайнеры' : 'Теплоходы'}
                  </Text>
                  <HeaderShips isSea={isSea} shipsSea={shipsSea} />
                </li>
                <li className={styles.menuItem} itemProp="name">
                  {!isSea && !shipsSea && (
                    <Anchor
                      weight="700"
                      href="/discount"
                      target="_blank"
                      decoration="none"
                      onClick={() => {
                        YaAndGaHeader('click_sale_page');
                      }}
                      itemProp="url"
                      variant={currentPage.includes('discount') && 'activePage'}
                    >
                      Скидки и акции
                    </Anchor>
                  )}
                </li>
                {!store.isB2bMode && (
                  <li className={styles.menuItem} itemProp="name">
                    <Anchor
                      weight="700"
                      href="/about"
                      target="_blank"
                      decoration="none"
                      onClick={() => {
                        YaAndGaHeader('click_about_company');
                      }}
                      itemProp="url"
                      variant={currentPage.includes('about') && 'activePage'}
                    >
                      О компании
                    </Anchor>
                  </li>
                )}
                <li
                  className={
                    activePage === '/contact'
                      ? styles.menuItemActive
                      : styles.menuItem
                  }
                  itemProp="name"
                >
                  <Anchor
                    weight="700"
                    href="/contact"
                    target="_blank"
                    decoration="none"
                    onClick={() => {
                      YaAndGaHeader('click_contacts_company');
                    }}
                    itemProp="url"
                    variant={currentPage.includes('contact') && 'activePage'}
                  >
                    Контакты
                  </Anchor>
                </li>
              </ul>
            )}
            {!firstLoading ? (
              <div className={styles.actions}>
                <Login
                  isMobile={isMobile}
                  isOutsPage={isOutsPage}
                  isStep={isStep}
                />
              </div>
            ) : (
              <div className={styles.actions}>
                {' '}
                <SkeletonCover width={185} height={37} />
              </div>
            )}
          </nav>
        </div>
      </header>
    );
  },
);
