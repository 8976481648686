import React, { useEffect } from 'react';
// import { useRouter } from 'next/router';
import { observer } from 'mobx-react-lite';
import styles from '../header.module.sass';
import { Anchor, Text } from '../../../../Text';
import { Icon } from '../../../../Icon';
import { IconSVG } from '../../../../SpriteSVG';
import { YaAndGaHeader } from '../../../../../lib/YaAndGaHelper';
import { useMarketingStore } from '../../../../../providers/RootStoreProvider';
import { HeaderSeason } from '../HeaderSeason';

const HeaderCruises = observer(({ isSea, shipsSea }) => {
  const allStartCities = isSea || shipsSea ? '/popdir?type=sea' : '/popdir';
  const store = useMarketingStore();

  useEffect(() => {
    store.setIsSea(isSea);
    store.getRiverDistention();
  }, [isSea]);
  // const allCruises =
  //   isSea || shipsSea
  //     ? '/result?type=sea'
  //     : '/result';

  const popularDir = store.popularDirections;
  const startCities = store.departureCities;

  return (
    <div className={styles.dropDownMenu}>
      <div className="container">
        <div className={styles.dropDownMenuWrapper}>
          <div>
            {isSea ? (
              <Text style={{ paddingLeft: 4 }} variant="heading-3">
                Города отправления
              </Text>
            ) : (
              <div className={styles.seasonItemWrapper}>
                <Anchor
                  href={allStartCities}
                  target="_blank"
                  variant="heading-3"
                  className={styles.seasonItem}
                >
                  Города отправления
                </Anchor>
                <div className={styles.arrowRight}>→</div>
              </div>
            )}

            <ul className={styles.dropDownMenuFeatures}>
              {startCities?.map((startCity, key) => (
                <li
                  key={key}
                  className={styles.dropDownMenuFeature}
                  itemProp="name"
                >
                  <Anchor
                    href={`/popdir/${startCity.href}${isSea ? '' : '/result'}`}
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => {
                      YaAndGaHeader('click_any_city_in_mega_menu');
                    }}
                    itemProp="url"
                  >
                    {startCity.h1}
                  </Anchor>
                </li>
              ))}
              {!isSea && (
                <Anchor
                  href={'/expeditionsCruise'}
                  target="_blank"
                  color="primaryColor"
                  variant="heading-3"
                >
                  <div className={styles.linkWrapper}>
                    Экспедиционные круизы
                    <Icon
                      leftGap={10}
                      icon={
                        <IconSVG
                          name={'common/big-right'}
                          width={16}
                          height={24}
                          title="Все круизы"
                        />
                      }
                      title="Все круизы"
                    />
                  </div>
                </Anchor>
              )}
            </ul>
          </div>
          <div>
            {isSea ? (
              <Text style={{ paddingLeft: 4 }} variant="heading-3">
                Популярные направления
              </Text>
            ) : (
              <div className={styles.seasonItemWrapper}>
                <Anchor
                  href={'/poproutes'}
                  target="_blank"
                  variant="heading-3"
                  className={styles.seasonItem}
                >
                  Популярные направления
                </Anchor>
                <div className={styles.arrowRight}>→</div>
              </div>
            )}
            <ul className={styles.dropDownMenuFeatures}>
              {popularDir?.map((popDir, key) => (
                <li key={key} className={styles.dropDownMenuFeature}>
                  <Anchor
                    href={`/popdir/${popDir.href}${isSea ? '' : '/result'}`}
                    target="_blank"
                    rel="nofollow"
                    onClick={() => {
                      YaAndGaHeader('click_any_departure_in_mega_menu');
                    }}
                  >
                    {popDir.h1}
                  </Anchor>
                </li>
              ))}
            </ul>
          </div>
          <div>
            {/* {!isSea && (
              <div>
                <Anchor
                  href={allCruises}
                  target="_blank"
                  color="primaryColor"
                  rel="noreferrer"
                  variant="heading-3"

                  onClick={() => {
                    YaAndGaHeader('click_all_cruises_in_mega_menu');
                  }}
                >
                  Все круизы{' '}
                  <Icon leftGap={10} icon={bigRight} title="Все круизы" />
                </Anchor>
                <div>
                  <Text variant="text-1">
                    Укажите свои пожелания чтобы найти
                    <br /> подходящие круизы
                  </Text>
                </div>
              </div>
            )} */}
            <HeaderSeason isSea={isSea} />
          </div>
        </div>
      </div>
    </div>
  );
});

export default HeaderCruises;
